<template>
  <v-dialog v-model="dialog" max-width="290">
    <v-card>
      <v-card-text>
        Activez vos sites pneumatiques
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="navigate()" > J'y vais </v-btn>
        <v-btn dark text @click="closePopup" > Plus tard </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import LAST_FORM_SUBMIT from "@/graphql/nissan/last-form-submit.gql";

export default {
  data () {
    return {
      dialog: false
    }
  },
  methods: {
    closePopup () {
      this.dialog = false;
    },
    navigate() {
      this.dialog = false;
      this.$router.push('/onboarding-form')
    }
  },
  mounted () {
    if (!this.$auth.ensure('ALERT_TYRE_NISSAN')) {
      return;
    }
    this.$apollo.query({
      query: LAST_FORM_SUBMIT
    }).then(res => {
      const form = res.data.nissanLastFormSubmit;
      setTimeout(() => {
        if (this.$route.name === 'nissan-onboarding-form') {
          return;
        }
        this.dialog = !form || !form.would_subscribe_tyre
      }, 6000);
    })
  }
}
</script>