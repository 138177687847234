<template>
	<div class="box" @click="handleClick(alert)">
			<header v-if="isClicked" class="box__header_clicked">
				<div  class="box__app">
					<i class="icon_clicked"></i> {{ alert.sender_name}}
				</div>
				<time>
					{{$moment(alert.date_add).format('DD/MM/YYYY')}} à {{$moment(alert.date_add).format('HH:mm')}} 
				</time>
			</header>
             <header v-else class="box__header">
				<div  class="box__app">
					<i class="icon"></i> {{ alert.sender_name}}
				</div>
				<time>
					{{$moment(alert.date_upd).format('DD/MM/YYYY')}} à {{$moment(alert.date_upd).format('HH:mm')}} 
				</time>
			</header>
			<section class="box__content">
				<p>{{alert.content.substring(0,150)}}...<span class="read"><span @click="getLink(alert)" class="alertLink">voir</span></span></p>
			</section>
			<footer class="box__footer">
				{{($moment(new Date()).diff($moment(alert.date_add),'minutes')>60 && 24>$moment(new Date()).diff($moment(alert.date_add),'hours'))?
				`il y a ${$moment.duration($moment().diff($moment(alert.date_add),'hours'))} heur(s)`:
				$moment(new Date()).diff($moment(alert.date_add),'hours') > 24?
				`il y a ${$moment(new Date()).diff($moment(alert.date_add),'days')} jour(s)`:
				`il y a ${$moment.duration($moment().diff($moment(alert.date_add),'minutes'))} minute(s)`}}

			</footer>
		</div>
</template>
<script>
import MARK_MESSAGE_READ  from '../../graphql/conversation/mark-messages-read-in-conversation.gql'

export default {
    data () {
      return {
          isClicked:false,
		  
      }
    },
    props: ["alert",'loadAlerts'],
	
    methods:{
        handleClick(alert){
			this.$apollo.mutate({
          mutation: MARK_MESSAGE_READ,
          variables: {
            id_order: parseInt(alert.id_order),
            data_source: alert.data_source,
            id_session:alert.id_session,
			fingerprint:alert.fingerprint,
			from:alert.from,
          }
        }).then(res=>{ 
          if(res.data.markMessagesReadInConversation.status==='OK'){
			this.isClicked=true
			this.$router.push(`/order/${alert.data_source}/${alert.id_order}/chat/${alert.id_session}`)
			this.$emit('opened')
			this.isClicked=false

          }else {
            this.errorMsg      = `Erreur : ${res.data.markMessagesReadInConversation.errorMsg}!`;
            this.errorSnackbar = true;
          }
        })

            

        },
		getLink(alert){
			this.isClicked=true
			this.$router.push(`/order/${alert.data_source}/${alert.id_order}/chat/${alert.id_session}`)
		}

    }
  }
</script>
<style>

.box {
	display: block;
	width: 500px;
	padding: 15px;
	background-color: #fff;
	top: 50%;
	z-index: 9;
}
	.box:nth-child(2) {
		top: calc(50% + 9px);
		z-index: 0;
		transform: scale(0.98);
		background-color: hsla(0, 0, 100%, 0.8)
	}
	
	.box:nth-child(3) {
		top: calc(50% + 18px);
		z-index: 0;
		transform: scale(0.96);
		background-color: hsla(0, 0, 100%, 0.6)
	}	
	
	.box__header {
		display: flex;
		justify-content: space-between;
		font-size: 0.9rem;
		color: black;
	}
    .box__header_clicked{
        display: flex;
		justify-content: space-between;
		font-size: 0.9rem;
        color: #aaa;
		
    }
    .icon_clicked{
        width: 10px;
	height: 10px;
	margin-right: 8px;
	border-radius: 50px;
	background-color: green;
	display: block;
    }
    
	
	.box__app {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	
	.box__footer {
		color: #aaa;
		font-size: 0.9rem;
	}


.icon {
	width: 10px;
	height: 10px;
	margin-right: 8px;
	border-radius: 50px;
	background-color: red;
	display: block;
}
.read{
    color:blue;
}
.alertLink{
  text-decoration: underline;
  color:red;
  cursor: pointer;
}
</style>
