export default {
  state: {
    apollo: undefined,
    router: undefined
  },
  mutations: {
    setUtils(state, data) {
      state[data.name] = data.value
    }
  }
}


