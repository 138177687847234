

function download(token, fileName) {
  fileName = fileName.replace(/\//g, '');
  let href = `https://cockpit.spid-tech.com/download/${token}/${fileName}`
  if (document.URL.match('localhost')) {
    href = `http://localhost:4000/download/${token}/${fileName}`;
  }
  if (document.URL.match('cockpit-test')) {
    href = `https://app-test.spid-tech.fr/download/${token}/${fileName}`;
  }
  window.open(href, '_blank');
}

function downloadSepaRequest(id_sepa_bank_requests,fileName,collectionDate) {
  const ids = id_sepa_bank_requests.map(id => `id=${id}`).join('&');
  const collectionDateParam = collectionDate ? `&ReqdColltnDt=${collectionDate}` : '';

  window.open(`https://cockpit.spid-tech.com/downloadSepaRequest/${fileName.replace(/\//g, '')}?${ids}${collectionDateParam}`, '_blank');
}
function downloadStock(id) {
  window.open(`https://cockpit.spid-tech.com/download-stock/${id}.csv`, '_blank');
}
function downloadAcessoriesProductXlsx(params) {
  const link = document.createElement('a');
  link.href = document.URL.match('localhost')
    ? `http://localhost:4000/download-products-catalogue?params=${params}`
    :`https://cockpit.spid-tech.com/download-products-catalogue?params=${params}`;
  link.setAttribute('download', `catalogue_${new Date().toISOString().substr(0, 10)}.xlsx`); //or any other extension
  document.body.appendChild(link);
  link.click();
}
export default {
  install (Vue) {
    Vue.prototype.$download = download;
    Vue.prototype.$downloadSepaRequest = downloadSepaRequest;
    Vue.prototype.$downloadStock = downloadStock;
    Vue.prototype.$downloadAcessoriesProductXlsx = downloadAcessoriesProductXlsx;
  }
}