<template>
    <v-menu
      :close-on-content-click="false"
      :nudge-width="500"
      left
      bottom
      
      transition="scale-transition"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          small
          fab
          :color="$auth.ensure('DARK_MODE') ? 'black' : 'white'"
          v-on="on"
          @click="handleClickNotif"
        >
         
        <v-badge left v-if="NotifIconNotClicked || count>0 ">
         <template v-slot:badge>
        <span v-if='count <100'>{{count}}</span>
         <span v-else>{{count}}+</span>
         </template>
          <v-icon large>notifications</v-icon>
        </v-badge>
        <v-icon v-else large>notifications</v-icon>
        </v-btn>
      </template>
        <v-card  class="big-box">
        <v-card-text class="box" v-if="loading"> <v-progress-circular indeterminate color="black"></v-progress-circular>recherche en cours....</v-card-text>
        <v-card-text class="box" v-else-if="!loading && !orderCrispAlerts.length">Pas de nouveaux messages</v-card-text>
        <v-card-text v-else v-for="(alert, i) in orderCrispAlerts" :key="i">
          <alert-component :alert="alert" v-on:opened="handleClick(alert)" :loadAlerts="loadData"></alert-component>
          <v-divider></v-divider>
        </v-card-text>
      </v-card>
    </v-menu>

</template>

<script>
import ALERT_MESSAGES from "../../graphql/order/order-crisp-alert.gql";
import alertComponent from "./alert.vue";
import bipurl from './bip.wav'

export default {
  components: { alertComponent },
  data() {
    return {
      loading: false,
      menu:false,
      NotifIconNotClicked:true,
      orderCrispAlerts: [],
      count:0,
      decrement:false
    };
  },
  props: ["view"],
  watch:{
   'orderCrispAlerts.length'(val){
       if(!this.count && !this.decrement && val>0){
          this.count++;
          this.playSound()
        }
   }

  },
  methods:{
    handleClickNotif(){
     this.NotifIconNotClicked=false;
     this.count=0;

    },
     playSound () {
      let  audio = new Audio(bipurl);
      audio.play();
    },
    handleClick(alert){
    this.orderCrispAlerts=this.orderCrispAlerts.filter((el)=>el.id_session!==alert.id_session)
    this.decrement=true;
    },
   loadData(){
    this.loading = true;
    this.$apollo
      .query({
        query: ALERT_MESSAGES,
        fetchPolicy:'no-cache'
      })
      .then((res) => {
        this.loading = false;
        this.orderCrispAlerts = res.data.orderCrispAlert;
        this.count=this.orderCrispAlerts.length;
      });
   }
  },
  created() {
    this.loadData();
      setInterval( () => {
         this.$apollo
      .query({
        query: ALERT_MESSAGES,
        fetchPolicy:'no-cache'
      })
      .then((res) => {
        this.decrement=false;
        this.orderCrispAlerts = res.data.orderCrispAlert;
        if(this.NotifIconNotClicked){
          this.count=this.orderCrispAlerts.length;
        }
      });
      }, 10*1000);
  },
};
</script>
<style>
.big-box{
  max-height:90vh;
  overflow-y: scroll;
}
.box{
  display: block;
	width: 500px;
	padding: 15px;
	background-color: #fff;
	top: 50%;
	z-index: 9;
}
</style>
