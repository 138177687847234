import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import appUtils from './appUtils'
import cart from './cart'
import authorizedData from './authorizedData'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    appUtils,
    authorizedData,
    cart
  }
})