// import axios from 'axios';
// import auth from '../utilities/auth';
import GET_CART_DETAILS from '../graphql/breakdown/get-cart-details.gql'
import GEt_DEFAULT_VALUES from '../graphql/breakdown/get-default-values.gql'
export default {
  state: () => ({
    cart: null,
    orderDraft: null,
    shortList: null,
    cartRecap: null,
    defaultValues: null,
    cartQuantity: 0
  }),
  mutations: {
    setCart(state, cart) {
      state.cart = cart;
    },
    setOrderDraft(state, orderDraft) {
      state.orderDraft = orderDraft;
      state.cartQuantity = 0;
      Object.keys(orderDraft).forEach(item => {
        if(orderDraft[item] && orderDraft[item]["products"]) {
          state.cartQuantity += orderDraft[item]["products"].reduce((a, b) => a + (b && b.quantity || 0), 0)
        }
      })
    },
    setShortList(state, shortList) {
      state.shortList = shortList;
    },
    setCartRecap(state, cartRecap) {
      state.cartRecap = cartRecap;
    },
    setDefaultValues(state, defaultValues) {
      state.defaultValues = defaultValues;
    },
    clearCart(state) {
      state.cart = null;
      state.orderDraft = null;
      state.shortList = null;
      state.cartRecap = null;
      state.cartQuantity = 0;
    }
  },
  getters: {
    getCart: (state) => state.cart,
    getOrderDraft: (state) => state.orderDraft,
    getShortList: (state) => state.shortList,
    getCartRecap: (state) => state.cartRecap,
    getDefaultValues: (state) => state.defaultValues,
    getCartQuantity: (state) => state.cartQuantity,
  },
  actions: {
    async getCartDetails ({ rootState, commit }, {domain, id_country, token}) {
      try {
        const res = await rootState.appUtils.apollo.query({
          fetchPolicy: 'no-cache',
          query: GET_CART_DETAILS,
          variables: {
            domain,
            id_country,
            token
          }
        })
        if(res && res.data && res.data.getCartDetails && res.data.getCartDetails.cart) {
          let cartDetails = JSON.parse(res.data.getCartDetails.cart);
          if(cartDetails && cartDetails.cart) {
            commit('setCart', cartDetails.cart)
          }
          if(cartDetails && cartDetails.orderDraft) {
            commit('setOrderDraft', cartDetails.orderDraft)
          }
          if(cartDetails && cartDetails.cartRecap) {
            commit('setCartRecap', cartDetails.cartRecap)
          }
          if(cartDetails && cartDetails.shortList) {
            commit('setShortList', cartDetails.shortList)
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async makeDefaultValues ({ rootState, commit }, {domain}) {
      const res = await rootState.appUtils.apollo.query({
        fetchPolicy: 'no-cache',
        query: GEt_DEFAULT_VALUES,
        variables: {
          domain,
        }
      })
      if(res && res.data && res.data.getDefaultValues) {
        commit('setDefaultValues', res.data.getDefaultValues)
      }
    },
    clearCart({ commit }) {
      commit('clearCart')
    }
  }
}