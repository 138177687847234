import router from './router'
import moment       from 'moment'
import LOGOUT from './graphql/user/logout.gql'

function ensure(authorization) {
  const currentUser = router.app.$store.state.auth.user;
  return currentUser && currentUser.authorizations && Boolean(currentUser.authorizations.find(el => el === authorization))
}

function ensureRoute(route) {
  if (route.meta && route.meta.authorization) {
    const auths = Object.keys(route.meta.authorization);
    for (let j=0; j < auths.length; j++) {
      if (route.meta.authorization[auths[j]] === ensure(auths[j])) {
        return true;
      }
    }
  }
  return false;
}

function getFirstAuthorizedRoute() {
  const routes = router.options.routes;
  for (let i = 0; i < routes.length; i++) {
    if (ensureRoute(routes[i]) && router.currentRoute.path !== routes[i].path) {
      return routes[i];
    }
  }
  return router.app.$store.state.auth.user ? { path: '/404'} : { path: '/login' };
}

router.afterLogin = function afterLogin() {
  const currentUser = router.app.$store.state.auth.user;
  if (currentUser && currentUser.authorizations && Boolean(currentUser.authorizations.find(el => el === 'KIA_ONBOARDING'))) {
    return router.push('/onboarding-kia-form')
  }
  if (currentUser && currentUser.authorizations && Boolean(currentUser.authorizations.find(el => el === 'SUZUKI_ONBOARDING'))) {
    return router.push('/onboarding-suzuki-form')
  }
  if (router.lastUrlBeforeLogin) {
    const to = router.lastUrlBeforeLogin.fullPath || router.lastUrlBeforeLogin;
    router.lastUrlBeforeLogin = null;
    if (to !== '/' || to !== '/users/password') {
      return router.push(to);
    }
  }
  if (currentUser && currentUser.authorizations && Boolean(currentUser.authorizations.find(el => el === 'IS_MAG'))) {
    return router.push('/orders')
  }
 
  if (!ensureRoute(router.currentRoute) || router.currentRoute.path === '/login') {
    router.push(getFirstAuthorizedRoute());
  }
};

router.beforeEach(async (to, from, next) => {
  if (to.name !== 'login' && (!localStorage.getItem('session-expire-at')
    || moment(localStorage.getItem('session-expire-at')).isBefore(moment()))) {
    if (localStorage.getItem('apollo-token')) {
      try {
        await router.app.$apolloProvider.clients.defaultClient.mutate({
          mutation: LOGOUT
        })
      } catch (e) {
        console.log(`Error invoking logout mutaion`);
      }
    }
    router.app.$store.dispatch('logout')
  }
  const currentUser = router.app.$store.state.auth.user;
  if (to.path === '/' && currentUser && currentUser.authorizations && Boolean(currentUser.authorizations.find(el => el === 'SPID_HOME'))) {
    return next();
  }
  if (to.path === '/' && currentUser && currentUser.authorizations && Boolean(currentUser.authorizations.find(el => el === 'SUZUKI_ONBOARDING'))) {
    return router.push('/onboarding-suzuki-form')
  }
  if (to.path === '/' && currentUser && currentUser.authorizations && Boolean(currentUser.authorizations.find(el => el === 'MONTHLY_REPORT'))) {
    return router.push('/reports/sales')
  }
  if (to.path === '/' && currentUser && currentUser.authorizations && Boolean(currentUser.authorizations.find(el => el === 'IS_MAG'))) {
    return router.push('/orders')
  }
  if (to.meta.public || router.app.$store.state.auth.user) {
    if (to.meta.authorization) {
      Object.keys(to.meta.authorization).forEach((key) => {
        if (ensure(key) !== to.meta.authorization[key]) {
          const route = getFirstAuthorizedRoute();
          if (router.currentRoute.path === route.path) {
            return router.back();
          }
          return router.push(route);
        }
      });
    }
    next();
  } else {
    router.lastUrlBeforeLogin = to;
    next({
      path: '/login'
    })
  }
});

function getAccessDisplayName(access) {
  switch (access) {
    case 'ADMIN_ACCESS':            return 'Admin';
    case 'SPID_ACCESS':             return 'Utilisateur Spid-tech';
    case 'SPID_ACCOUNTANT':         return 'Comptable Spid-tech';
    case 'NISSAN_ACCESS':           return 'Onboarding Nissan';
    case 'B2B_ACCESS':              return 'Client Comptable';
    case 'B2B_ACCESS_MAG':          return 'Client Magasinier';
    case 'B2B_SITE_MANAGER':        return 'Client Responsable Site';
    case 'B2B_ADMIN_ACCESS':        return 'Client Admin';
    case 'B2B_ADMIN_ACCESS_NISSAN': return 'Client Admin Nissan';
    case 'B2B_ADMIN_ACCESS_KIA':    return 'Client Admin KIA';
    case 'KIA_ADMIN':               return 'Admin KIA';
    case 'B2B_ADMIN_ACCESS_SUZUKI': return 'Client Admin SUZUKI';
    case 'SUZUKI_ADMIN':            return 'Admin SUZUKI';
    case 'SUZUKI_ZONE_MANAGER':     return 'Zone Admin SUZUKI';
    default:                        return access;
  }
}

export default {
  install (Vue) {
    Vue.prototype.$auth = { ensure, getAccessDisplayName }
  }
}
