import { onLogout, onLogin } from '@/vue-apollo'
import LOGIN from '../graphql/user/login.gql'
import forgotPassword from '../graphql/user/forgot.gql'

export default {
  state: {
    user: JSON.parse(localStorage.getItem('currentUser')),
  },
  actions: {
    async forgotPassword ({ rootState }, auth) {
      return await rootState.appUtils.apollo.query({
        fetchPolicy: 'no-cache',
        query: forgotPassword,
        variables: { email: auth.email }
      })
    },
    logout ({ rootState, commit }) {
      onLogout(rootState.appUtils.apollo);
      localStorage.clear();
      commit('setConnectedUser', undefined)
      if (rootState.appUtils.router) {
        rootState.appUtils.router.go()
      }
    },
    async login ({ rootState, commit, dispatch }, auth) {
      const res = await rootState.appUtils.apollo.mutate({
        fetchPolicy: 'no-cache',
        mutation: LOGIN,
        variables: { email: auth.email, password: auth.password }
      })
      if (res.data.login && res.data.login.status === 'KO') {
        alert(`Erreur authentification ${res.data.login.errorMsg}`);
      } else {
        onLogin(rootState.appUtils.apollo, res.data.login.user.token);
        delete res.data.login.user.token;
        const user = res.data.login.user
        localStorage.setItem('currentUser', JSON.stringify(user) );
        commit('setConnectedUser', user)
        dispatch('getAuthorizedData')
        rootState.appUtils.router.afterLogin();
      }
    }
  },
  mutations: {
    setConnectedUser (state, user) {
      state.user = user
    }
  },
  getters: {
    getConnectedUser: state => {
      return state.user
    }
  }
}

