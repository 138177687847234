import _groups from '@/graphql/shop/groups.gql'
import _shopsV2 from '@/graphql/shop/shops-v2.gql'
import _billing from '@/graphql/billing/billing-accounts-tiny.gql'
import Vue from 'vue'

export default {
  state: {
    groups: [],
    billing_accounts: [],
    shops: [],
    data_sources: [],
  },

  actions: {
    async getAuthorizedGroups ({ rootState, commit }) {
      const groups = await rootState.appUtils.apollo.query({
        fetchPolicy: 'no-cache',
        query: _groups,
      })
      commit('setGroups', groups.data.groups)
    },
    async getAuthorizedShops ({ rootState, commit }) {
      const shops = await rootState.appUtils.apollo.query({
        fetchPolicy: 'no-cache',
        query: _shopsV2,
        variables: {
          havingClientGroupOnly: true
        }
      })
      commit('setShops', shops.data.shopsV2)
      commit('setDataSources', shops.data.shopsV2)
    },
    async getAuthorizedBillingAccount ({ rootState, commit }) {
      const billing = await rootState.appUtils.apollo.query({
        fetchPolicy: 'no-cache',
        query: _billing,
      })
      commit('setBillingAccounts', billing.data.billingAccounts)
    },
    async getAuthorizedData({ dispatch }, mode) {
      if (mode === 'group' || !mode) {
        await dispatch('getAuthorizedGroups')
      }
      if (mode === 'billingAccount' || !mode) {
        await dispatch('getAuthorizedBillingAccount')
      }
      if (mode === 'shop' || !mode) {
        await dispatch('getAuthorizedShops')
      }
    }
  },

  mutations: {
    setDataSources (state, shops) {
      const dataSources = shops.reduce((prev, cur) => {
        if (!prev.includes(cur.data_source)) {
          prev.push(cur.data_source);
        }
        return prev;
      }, []);
      Vue.set(state, 'data_sources', dataSources);
    },
    setGroups (state, groups) {
      Vue.set(state, 'groups', groups)
    },
    setBillingAccounts (state, billings) {
      Vue.set(state, 'billing_accounts', billings)
    },
    setShops (state, shops) {
      Vue.set(state, 'shops', shops)
    },
    setAuthorizedMarketPlaces (state, marketplaces) {
      state.marketplaces = marketplaces
    }
  },

  getters: {
    getAuthorizedGroupById: (state) => (id) => {
      return state.groups.find(el => parseInt(el.id) === parseInt(id))
    },
    getAuthorizedShopById: (state) => (id) => {
      return state.shops.find(el => parseInt(el.id_shop_info) === parseInt(id))
    },
    getAuthorizedBillingAccountById: (state) => (id) => {
      return state.billing_accounts.find(el => parseInt(el.id) === parseInt(id))
    },
    getShopsByDatasource (state) {
      return data_source => state.shops.filter(el => el.data_source === data_source);
    },
    getGroups (state) {
      return state.groups
    },
    getBillingAccounts (state) {
      return state.billing_accounts
    },
    getShops (state) {
      return state.shops
    },
    getMarketPlaces(state) {
      return state.marketplaces
    },
    getDataSources (state) {
      return state.data_sources
    }
  }
}


