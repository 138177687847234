<template>
  <v-card>
    <h2 class="buy-product-cart">Panier</h2>
    <v-layout row wrap v-if="getCart && getOrderDraft" class="m-0">
      <v-flex md12 xs12 class="pa-2">
        <div v-for="item in Object.keys(getOrderDraft)" :key="item" class="mb-4">
          <p class="mb-0"><strong>{{`Concession ${getOrderDraft[item]['shop'].locations[0]["postal_code"].slice(0, 2)}`}}</strong></p>
          <table class="cart-details">
            <tr>
              <th class="bordered">Référence</th>
              <th class="bordered">Prix ht</th>
              <th class="bordered">TVA</th>
              <th class="bordered">Prix TTC</th>
              <th class=""></th>
            </tr>
            <tr v-for="product in getOrderDraft[item]['products']" :key="product.reference">
              <td class="bordered">{{product.reference}}</td>
              <td class="bordered">{{product.total_final_price_tax_excl.toFixed(2)}}</td>
              <td class="bordered">{{(product.total_final_price_tax_incl - product.total_final_price_tax_excl).toFixed(2)}}</td>
              <td class="bordered">{{product.total_final_price_tax_incl.toFixed(2)}}</td>
              <td class="quantity">
                <span @click="updateQuantity(getOrderDraft[item]['shop'], product, 'decrement')">-</span>
                <span>{{product.quantity}}</span>
                <span @click="updateQuantity(getOrderDraft[item]['shop'], product, 'increment')">+</span>
                <v-btn icon @click="deleteProduct(getOrderDraft[item]['shop'], product)"><v-icon>delete</v-icon></v-btn>
              </td>
              <td> 
                <v-layout row wrap v-if="getCart">
                  <v-flex xs6 class="pa-2">
                    <v-text-field
                      label="Vin"
                      v-model="vin_number[product.reference]"
                      @keyup="updateCustomSteps"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs6 class="pa-2">
                    <v-text-field
                      label="N° dossier interne (facultatif)"
                      v-model="b2b_reference[product.reference]"
                      @keyup="updateCustomSteps"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </td>
            </tr>
            <tr v-if="getOrderDraft[item]['total_shipping_tax_excl']">
              <td>Frais de livraison</td>
              <td>{{getOrderDraft[item]["total_shipping_tax_excl"].toFixed(2)}}</td>
              <td>{{(getOrderDraft[item]["total_shipping_tax_incl"] - getOrderDraft[item]["total_shipping_tax_excl"]).toFixed(2)}}</td>
              <td>{{getOrderDraft[item]["total_shipping_tax_incl"].toFixed(2)}}</td>
            </tr>
            <tr v-else>
              <td>Frais de livraison: </td>
              <td colspan="3"><strong>pas de tarif de livraison configuré. confirmez la commande pour recevoir un devis de livraison</strong></td>
            </tr>
          </table>
        </div>
        <table class="cart-details">
          <tr>
            <th></th>
            <th class="bordered">Prix ht</th>
            <th class="bordered">TVA</th>
            <th class="bordered">Prix TTC</th>
          </tr>
          <tr>
            <td class="bordered"><strong>Total</strong></td>
            <td class="bordered"><strong>{{getCartRecap.total_paid_tax_excl.toFixed(2)}}</strong></td>
            <td class="bordered"><strong>{{(getCartRecap.total_paid_tax_incl - getCartRecap.total_paid_tax_excl).toFixed(2)}}</strong></td>
            <td class="bordered"><strong>{{getCartRecap.total_paid_tax_incl.toFixed(2)}}</strong></td>
          </tr>
        </table>
       <div class="mt-3">
          <v-btn color="primary" @click="redirectToBuyProduct">
            Ajouter d'autres pièces
          </v-btn>
          <v-btn  @click="createNewOrder">
            Confirmer la commande
          </v-btn>
        </div>
      </v-flex>
      <v-flex xs12 class="pa-2">
          <v-select
            v-model="location"
            :items="getCart.locations"
            item-value="id"
            item-text="name"
            label="Adresse de livraison ?"
          ></v-select>
        <label></label>
      </v-flex>
    </v-layout>
    <v-snackbar
      :timeout="5000"
      :bottom="true"
      color="#2E7D32"
      v-model="successSnackbar"
    >
      {{successMsg}}
    </v-snackbar>
    <v-snackbar
      :bottom="true"
      :timeout="5000"
      v-model="errorSnackbar"
      >
        {{errorMsg}}
      <v-btn @click="errorSnackbar = false" color="pink" text>
        Close
      </v-btn>
    </v-snackbar>
  </v-card>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import CREATE_TOKEN from '../../graphql/breakdown/create-token.gql';
import CREATE_NEW_ORDER from '../../graphql/breakdown/create-new-order.gql';
import CHANGE_PRODUCT_QUANTITY from '../../graphql/breakdown/change-product-quantity.gql';
import UPDATE_STEPS from '../../graphql/breakdown/update-steps.gql';
import DELETE_PRODUCT_FROM_CART from '../../graphql/breakdown/delete-product-from-cart.gql';
import UPDATE_ADDRESSES from '../../graphql/breakdown/update-addresses.gql';
import PRODUCTS_LIST from '../../graphql/breakdown/products-list.gql'

export default {
  name: "CartDetails",
  data() {
    return {
      timeOut: null,
      vin_number: {},
      b2b_reference: {},
      successSnackbar: null,
      successMsg: null,
      errorSnackbar: null,
      errorMsg: null,
      location: null
    }
  },
  computed: {
    ...mapGetters({
      getCart: "getCart",
      getOrderDraft: "getOrderDraft",
      getShortList: "getShortList",
      getCartRecap: "getCartRecap",
      getDefaultValues: "getDefaultValues",
    }),
  },
  async created() {
    await this.loadData();
    if(this.getCart && this.getCart.position) {
      this.location = this.getCart.position;
    }
    if(
      this.getCart &&
      this.getCart.checkout_session_data &&
      JSON.parse(this.getCart.checkout_session_data)["custom-checkout-step"] &&
      JSON.parse(this.getCart.checkout_session_data)["custom-checkout-step"]["b2b_reference"]
    ) {
      this.b2b_reference = JSON.parse(this.getCart.checkout_session_data)["custom-checkout-step"]["b2b_reference"];
    }
    if(
      this.getCart &&
      this.getCart.checkout_session_data &&
      JSON.parse(this.getCart.checkout_session_data)["custom-checkout-step"] &&
      JSON.parse(this.getCart.checkout_session_data)["custom-checkout-step"]["vin_number"]
    ) {
      this.vin_number = JSON.parse(this.getCart.checkout_session_data)["custom-checkout-step"]["vin_number"];
    }
  },
  watch: {
    async location() {
      let address = this.getCart.locations.find(el => el.id === this.location);
      
      let tokenApi = localStorage.getItem('tokenApi');
      let updatedAddress = await this.$apollo.mutate({
        mutation: UPDATE_ADDRESSES,
        variables: {
          domain: process.env.CLICK_PARTS, 
          position: this.location, 
          token: tokenApi,
          address: {
            firstname: "",
            lastname: "",
            id_country: this.getCart.id_country,
            address1: `${address.address_line1 ? address.address_line1 : ""} ${address.address_line2 ? address.address_line2 : ""}`,
            city: address.city,
            name: address.name,
            postcode: address.postal_code,
            phone: ""
          }
        }
      });
      await this.getCartDetails({
        domain: process.env.CLICK_PARTS,
        id_country: this.getDefaultValues.id_country,
        token: tokenApi
      });
    }
  },
  methods: {
    ...mapActions({
      getCartDetails: "getCartDetails",
      makeDefaultValues: "makeDefaultValues",
      clearCart: "clearCart",
    }),
    updateCustomSteps() {
      if (this.timeOut) {
        clearTimeout(this.timeOut);
      }
      let tokenApi = localStorage.getItem('tokenApi');
      const params = [
        {'checkout-personal-information-step': 'step_is_complete', check: true},
        {'checkout-personal-information-step': 'step_is_reachable', check: true},
        {'checkout-addresses-step': 'step_is_complete', check: true},
        {'checkout-addresses-step': 'use_same_address', check: true},
        {'checkout-delivery-step': 'step_is_reachable', check: true},
        {'checkout-delivery-step': 'step_is_complete', check: true},
        {'checkout-delivery-step': 'collect', check: ""},
        {'checkout-delivery-step': 'pickup', check: ""},
        {'checkout-delivery-step': 'phone', check: ""},
        {'custom-checkout-step': 'step_is_reachable', check: true},
        {'custom-checkout-step': 'step_is_complete', check: true},
        {'checkout-payment-step': 'step_is_reachable', check: true},
        {'custom-checkout-step': 'vin_number', check: this.vin_number},
        {'custom-checkout-step': 'b2b_reference', check: this.b2b_reference},
        {'checkout-payment-step': 'step_is_complete', check: true}
      ]
      this.timeOut = setTimeout(async () => {
        let newSteps = this.updateSteps(this.getCart.checkout_session_data, params)
        await this.$apollo.mutate({
          mutation: UPDATE_STEPS,
          variables: {
            domain:  process.env.CLICK_PARTS, 
            checkout_session_data: newSteps, 
            token: tokenApi
          }
        });
      }, 500)
    },
    redirectToBuyProduct() {
      this.$router.push('/breakdown/buy-product');
      this.$emit('toggleDialog');
    },
    updateSteps(steps, params) {
      let newSteps = JSON.parse(steps);
      params.forEach(param => {
        let key = Object.keys(param)[0];
        let value = Object.values(param)[0];
        newSteps[key][value] = Object.values(param)[1];
      });
      return JSON.stringify(newSteps);
    },
    async updateQuantity(shop, product, action) {
      let quantity = product.quantity;
      if(action === "increment") {
        let products = await this.$apollo.query({
          fetchPolicy: 'no-cache',
          query: PRODUCTS_LIST,
          variables: {
            search: product.reference,
            domain: process.env.CLICK_PARTS,
            rate: this.getDefaultValues.rate
          }
        })
        if(products && products.data && products.data.productsList && products.data.productsList.length) {
          let productsList = products.data.productsList;
          let findedProduct = productsList.find(p => p.shop_data_source === shop.data_source && p.shop_id_shop === shop.external_id &&p.product_reference === product.reference);
          if(findedProduct && findedProduct.stock_count && findedProduct.stock_count <= quantity) {
            alert("Pas de quantité dans le stock")
            return;
          }
        }
        quantity++;
      } else if(action === "decrement") {
        quantity--;
      }
      let tokenApi = localStorage.getItem('tokenApi');
      let updatetdProduct = await this.$apollo.mutate({
        mutation: CHANGE_PRODUCT_QUANTITY,
        variables: {
          domain:  process.env.CLICK_PARTS, 
          data_source: shop.data_source, 
          id_shop: shop.external_id, 
          quantity, 
          referenceId: product.reference, 
          token: tokenApi
        }
      });
      await this.getCartDetails({
        domain: process.env.CLICK_PARTS,
        id_country: this.getDefaultValues.id_country,
        token: tokenApi
      });
      if(updatetdProduct && updatetdProduct.data && updatetdProduct.data.changeProductQuantity && updatetdProduct.data.changeProductQuantity.status && updatetdProduct.data.changeProductQuantity.status === 'OK') {
        this.errorSnackbar = false;
        this.errorMsg = "";
        this.successSnackbar = true;
        this.successMsg = "Quantité modifié avec succés";
      } else {
        this.successSnackbar = false;
        this.successMsg = "";
        this.errorSnackbar = true;
        this.errorMsg = "Problème du modification du quantité du produit";
      }
    },
    async deleteProduct(shop, product) {
      let tokenApi = localStorage.getItem('tokenApi');
      let deletedProduct = await this.$apollo.mutate({
        mutation: DELETE_PRODUCT_FROM_CART,
        variables: {
          domain: process.env.CLICK_PARTS, 
          data_source: shop.data_source, 
          id_shop: shop.external_id, 
          referenceId: product.reference, 
          token: tokenApi
        }
      });
      await this.getCartDetails({
        domain: process.env.CLICK_PARTS,
        id_country: this.getDefaultValues.id_country,
        token: tokenApi
      });
      if(deletedProduct && deletedProduct.data && deletedProduct.data.deleteProductFromCart && deletedProduct.data.deleteProductFromCart.status && deletedProduct.data.deleteProductFromCart.status === 'OK') {
        this.errorSnackbar = false;
        this.errorMsg = "";
        this.successSnackbar = true;
        this.successMsg = "Produit supprimé avec succés";
      } else {
        this.successSnackbar = false;
        this.successMsg = "";
        this.errorSnackbar = true;
        this.errorMsg = "Problème de supression du produit";
      }
    },
    async createNewOrder(){
      if(!this.location) {
        alert( "Sélectionner une addresse" )
        return;
      }
      let tokenApi = localStorage.getItem('tokenApi');
      const params = [
        {'checkout-personal-information-step': 'step_is_complete', check: true},
        {'checkout-personal-information-step': 'step_is_reachable', check: true},
        {'checkout-addresses-step': 'step_is_complete', check: true},
        {'checkout-addresses-step': 'use_same_address', check: true},
        {'checkout-delivery-step': 'step_is_reachable', check: true},
        {'checkout-delivery-step': 'step_is_complete', check: true},
        {'checkout-delivery-step': 'collect', check: ""},
        {'checkout-delivery-step': 'pickup', check: ""},
        {'checkout-delivery-step': 'phone', check: ""},
        {'custom-checkout-step': 'step_is_reachable', check: true},
        {'custom-checkout-step': 'step_is_complete', check: true},
        {'checkout-payment-step': 'step_is_reachable', check: true},
        {'custom-checkout-step': 'vin_number', check: JSON.stringify(this.vin_number)},
        {'custom-checkout-step': 'b2b_reference', check: JSON.stringify(this.b2b_reference)},
        {'checkout-payment-step': 'step_is_complete', check: true}
      ]
      let newSteps = this.updateSteps(this.getCart.checkout_session_data, params)
      await this.$apollo.mutate({
        mutation: UPDATE_STEPS,
        variables: {
          domain:  process.env.CLICK_PARTS, 
          checkout_session_data: newSteps, 
          token: tokenApi
        }
      });
      let result = await this.$apollo.mutate({
        mutation: CREATE_NEW_ORDER,
        variables: {
          domain:  process.env.CLICK_PARTS, 
          id_carrier: this.getCart.id_carrier, 
          id_lang: this.getDefaultValues.id_lang, 
          id_currency: this.getCart.id_currency, 
          id_address_delivery: this.getCart.id_address_delivery, 
          id_address_invoice: this.getCart.id_address_invoice,
          token: tokenApi
        }
      });
      if(result && result.data && result.data.createNewOrder && result.data.createNewOrder.status && result.data.createNewOrder.status === 'OK') {
        this.errorSnackbar = false;
        this.errorMsg = "";
        this.successSnackbar = true;
        this.successMsg = "Commande créée avec succés";
        alert("Commande créée avec succés");

      } else {
        this.successSnackbar = false;
        this.successMsg = "";
        this.errorSnackbar = true;
        this.errorMsg = "Problème de création de la commande";
      }
      this.$emit('toggleDialog');
      this.clearCart();
      let newToken = await this.$apollo.mutate({
        mutation: CREATE_TOKEN,
        variables: { domain: process.env.CLICK_PARTS }
      });
      if(newToken && newToken.data && newToken.data.createToken && newToken.data.createToken.token) {
        tokenApi = newToken.data.createToken.token;
        localStorage.setItem('tokenApi', tokenApi);
      }
      await this.getCartDetails({
        domain: process.env.CLICK_PARTS,
        id_country: this.getDefaultValues.id_country,
        token: tokenApi,
      });
      this.$router.push('follow-orders');
    },
    async loadData() {
      let tokenApi = localStorage.getItem('tokenApi');
      if(!tokenApi) {
        let result = await this.$apollo.mutate({
          mutation: CREATE_TOKEN,
          variables: {domain: process.env.CLICK_PARTS}
        });
        if(result && result.data && result.data.createToken && result.data.createToken.token) {
          tokenApi = result.data.createToken.token;
          localStorage.setItem('tokenApi', tokenApi);
        }
      }
      if(!this.getDefaultValues) {
        await this.makeDefaultValues({ domain: process.env.CLICK_PARTS });
      }
      if(!this.getCart) {
        await this.getCartDetails({
          domain: process.env.CLICK_PARTS,
          id_country: this.getDefaultValues.id_country,
          token: tokenApi
        });
      }
    }
  }
}
</script>
<style>
.buy-product-cart {
  text-align: center;
  padding: 10px;
}
table.cart-details {
  border-collapse: collapse;
  width: 100%;
}
table.cart-details td.quantity {
  text-align: center;
  width: 150px;
}
table.cart-details td.quantity span {
  padding: 4px;
  cursor: pointer;
}
table.cart-details th, table.cart-details td {
  padding: 5px;
}
table.cart-details th.bordered, table.cart-details td.bordered {
  border:1px solid black;
}
.mb-0 {
  margin-bottom: 0;
}
.m-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
</style>