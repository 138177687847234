<template>
  <v-dialog v-model="dialog" max-width="290">
    <v-card> 
      <v-card-text class="pa-3" >
        Faisons connaissance!
        Complétez votre profil pour favoriser la communication entre nous.
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary"  @click="navigate()" > J'y vais </v-btn>
        <v-btn  dark @click="closePopup" > Plus tard </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import USER_INFOS from "../../graphql/user/user-infos.gql";

  export default {
    data () {
      return {
        dialog: false,
      }
    },
    methods: {
      closePopup () {
        this.dialog = false;
      },
      navigate() {
        this.dialog = false;
        this.$router.push('/user/account')
      }
    },
    mounted () {
    const id_user = parseInt(
      JSON.parse(localStorage.getItem("currentUser"))["id_user"]
    );
    this.id_user = id_user;
    this.$apollo
      .query({
        query: USER_INFOS,
        variables: {
          id_user,
        },
      })
      .then((res) => {
        const {  first_name, last_name} =
          res.data.userInfos;
        if(!first_name || !last_name){
            this.dialog=true
        }
      });
  },
  }
</script>